<template>
  <div>
    <h1 class="text-2xl sm:text-3xl md:text-4xl mb-8 md:-mt-8">Your Personalised Quotes</h1>
    <div class="px-8 pt-6 pb-10 md:pb-2 mb-4">

      <div class="flex-col lg:flex-row flex justify-between relative px-0 sm:px-4">
        <div class="flex-1 order-1 text-left">
          <h2 class="text-xl"><span class="block font-bold">School:</span>{{school.name}}</h2>
          
          <div class="mb-4 mt-4">
            <label class="block text-gray-700 text-sm font-bold mb-2 text-left" for="headcount">
              Staff Headcount For School Wellbeing cover<span class="text-red-600"> *</span>
            </label>
            <input class="shadow appearance-none border rounded w-full max-w-xs py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="headcount" type="number" placeholder="20" v-model="headcount" required step="1">
          </div>

          <button class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-8 rounded focus:outline-none focus:shadow-outline" @click="updateQuotes">
            Update Quotes
          </button>

        </div>

        <div class="flex-1 order-2 mt-8 mx-auto max-w-2xl lg:max-w-lg lg:mx-0 lg:mt-0">
          <img src="/ad.png" alt="School Wellbeing Breakdown" class="w-full h-full"/>
        </div>


      </div>

      <hr class="border-gray-300 mt-10">

      <div class="w-full mt-12">
        <div v-if="!showQuotes">
          <div class="inline-flex items-center px-2 md:px-6 py-4 font-semibold leading-6 text-sm rounded-md text-gray-800 transition ease-in-out duration-150 cursor-not-allowed" style="min-width: 300px">
            <span class="text-lg md:text-2xl">Please click 'Update Quotes' above to get your personalised quotes.</span>
          </div>
        </div>
        <div v-else>
          <div v-if="loadingQuotes">
            <div class="flex-col sm:flex-row inline-flex items-center px-10 py-4 font-semibold leading-6 text-sm rounded-md text-gray-800 transition ease-in-out duration-150 cursor-not-allowed" style="min-width: 300px">
              <svg class="animate-spin -ml-1 mr-3 h-12 w-12 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25 text-gray-800" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="2"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              <span class="text-xl sm:text-2xl mt-2 sm:mt-0 sm:ml-2">Loading Quotes...</span>
            </div>
          </div>
          <div class="flex-wrap flex lg:flex-nowrap" v-else>
            <div class="w-full mb-8 md:mb-0 md:w-1/2 order-1 px-0 sm:px-4 lg:w-full" style="min-width:265px;">
              <div class="rounded w-full h-auto md:h-60 hover:bg-gray-200 text-gray-800  quote-box border border-swb" v-bind:class="{ 'bg-gray-100' : !quote.level1.enabled, 'bg-swb': quote.level1.enabled, 'quote-box-active' : quote.level1.enabled, 'loading-quote' : quote.level1.loading}">
                <div class="inline-flex items-center py-8 px-4 md:py-8 md:px-8 font-semibold leading-6 text-sm rounded-md text-gray-800 transition ease-in-out duration-150" v-if="quote.level1.loading">
                  <svg class="animate-spin -ml-1 mr-2 h-10 w-10 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25 text-gray-800" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="2"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  <span class="text-lg ml-2" style="min-width: 100px;">Saving Quote...</span>
                </div>
                <div v-if="!quote.level1.enabled && !quote.level1.loading" @click="createQuote(1)" class="h-full flex flex-col justify-around py-4 px-4 md:py-8 md:px-8">
                  <h2 class="text-2xl mb-2 font-bold">Essential</h2>
                  <span class="block text-4xl font-bold">£{{quote.level1.premium}}</span>
                  <span class="block mt-3 text-gray-500 quote-click">Click to save this quote</span>
                </div>
                <div v-else-if="quote.level1.enabled && !quote.level1.loading" class="text-white py-4 px-8">
                  <h2 class="text-xl mb-2 font-bold">Essential</h2>
                  <span class="block text-4xl font-bold">£{{quote.level1.premium}}</span>
                  <span class="block mt-2">
                    <span class="text-lg">Quote Ref:</span> <br>
                    <span class="text-xl font-bold">{{ quote.level1.ref}}</span>
                  </span>
                  <div class="flex justify-around mt-4">
                    <a :href="'https://admin.kisdigital.co.uk/quote/view/' + quote.level1.quote.quote_id" target="_blank" rel="noopener" class="bg-white text-gray-800 rounded py-2 px-6 text-lg md:text-sm font-bold hover:bg-green-200" @click="acceptQuote(2)">Accept</a>
                    <a @click="emailQuote(1)" class="bg-white text-gray-800 rounded py-2 px-6 text-lg md:text-sm font-bold hover:bg-green-200 cursor-pointer" :disabled="quote.level1.email == 'sending' || quote.level1.email == 'sent'" v-bind:class="{'pointer-events-none' : (quote.level1.email == 'sending' || quote.level1.email == 'sent')}">
                      <span v-if="!quote.level1.email">Email</span>
                      <span v-else-if="quote.level1.email == 'sending'">
                        <svg class="animate-spin mx-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle class="opacity-50 text-gray-800" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="2"></circle>
                          <path class="opacity-100" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                      </span>
                      <span v-else-if="quote.level1.email == 'sent'" class="flex justify-around items-center">
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" class="h-3 w-3 mr-2 font-light" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" stroke="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
                        Sent
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/2 order-2 px-0 sm:px-4 lg:w-full" style="min-width:265px;">
              <div class="rounded w-full h-auto md:h-60 hover:bg-gray-200 text-gray-800 quote-box border border-swb" v-bind:class="{ 'bg-gray-100' : !quote.level2.enabled, 'bg-swb': quote.level2.enabled, 'quote-box-active' : quote.level2.enabled, 'loading-quote' : quote.level2.loading}">
                <div class="inline-flex items-center py-8 px-4 md:py-8 md:px-8 font-semibold leading-6 text-sm rounded-md text-gray-800 transition ease-in-out duration-150" v-if="quote.level2.loading">
                  <svg class="animate-spin -ml-1 mr-2 h-10 w-10 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25 text-gray-800" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="2"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  <span class="text-lg ml-2" style="min-width: 100px;">Saving Quote...</span>
                </div>
                <div v-if="!quote.level2.enabled && !quote.level2.loading" @click="createQuote(2)" class="h-full flex flex-col justify-around py-4 px-4 md:py-8 md:px-8">
                  <h2 class="text-2xl mb-2 font-bold">Premium</h2>
                  <span class="block text-4xl font-bold">£{{quote.level2.premium}}</span>
                  <span class="block mt-3 text-gray-500 quote-click">Click to save this quote</span>
                </div>
                <div v-else-if="quote.level2.enabled && !quote.level2.loading" class="text-white py-4 px-8">
                  <h2 class="text-xl mb-2 font-bold">Premium</h2>
                  <span class="block text-4xl font-bold">£{{quote.level2.premium}}</span>
                  <span class="block mt-2">
                    <span class="text-lg">Quote Ref:</span> <br>
                    <span class="text-xl font-bold">{{ quote.level2.ref}}</span>
                  </span>
                  <div class="flex justify-around mt-4">
                    <a :href="'https://admin.kisdigital.co.uk/quote/view/' + quote.level2.quote.quote_id" target="_blank" rel="noopener" class="bg-white text-gray-800 rounded py-2 px-6 text-lg md:text-sm font-bold hover:bg-green-200" @click="acceptQuote(2)">Accept</a>
                    <a @click="emailQuote(2)" class="bg-white text-gray-800 rounded py-2 px-6 text-lg md:text-sm font-bold hover:bg-green-200 cursor-pointer" :disabled="quote.level2.email == 'sending' || quote.level2.email == 'sent'" v-bind:class="{'pointer-events-none' : (quote.level2.email == 'sending' || quote.level2.email == 'sent')}">
                      <span v-if="!quote.level2.email">Email</span>
                      <span v-else-if="quote.level2.email == 'sending'">
                        <svg class="animate-spin mx-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle class="opacity-50 text-gray-800" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="2"></circle>
                          <path class="opacity-100" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                      </span>
                      <span v-else-if="quote.level2.email == 'sent'" class="flex justify-around items-center">
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" class="h-3 w-3 mr-2 font-light" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" stroke="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
                        Sent
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/2 mt-8 lg:mt-0 order-3 px-0 sm:px-4 lg:w-full" style="min-width:265px;">
              <div class="rounded bg-swb w-full h-auto md:h-60 text-white py-8 px-4 md:py-4 md:px-8">
                <div class="h-full">
                  <h2 class="text-2xl mb-2 font-bold">Bespoke Cover</h2>
                  <p class=" font-bold">For bespoke cover for your school, please contact our team below to discuss your options.</p>
                  <div class="flex justify-around mt-6">
                    <a href="tel:01773 814407" class="bg-white text-gray-800 rounded py-2 px-6 text-lg md:text-sm font-bold hover:bg-green-200">Call</a>
                    <a href="mailto:sales@uk-sas.co.uk" class="bg-white text-gray-800 rounded py-2 px-6 text-lg md:text-sm font-bold hover:bg-green-200">Email</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        
      </div>

      <hr class="border-gray-300 mt-10">

      <div class="w-full mt-12">
        <div class="flex-1 order-2 mt-8 lg:mt-0 sm:px-4">
          <a href="//school-wellbeing.s3.eu-west-2.amazonaws.com/School+Wellbeing+Brochure.pdf" target="_blank" rel="noopener"
          class="flex justify-center items-center rounded h-full relative bg-white text-swb-dark border-swb-dark border-2 font-bold text-base sm:text-lg lg:text-xl px-4 lg:px-8 py-8 lg:py-8 hover:bg-swb hover:text-white overflow-hidden">
            <span class="absolute w-full h-full z-0 opacity-40 swb-watermark"></span>
            <p class="z-10">For a breakdown of each level and the benefits they contain, <span class="font-black block text-base sm:text-xl lg:text-2xl">download our brochure here</span></p>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

const api = "https://admin.kisdigital.co.uk/api/v1/"
const _token = "h72vjlVkHGUN3Se+9yBtdiOpTZFECoi/rxmO2alDuZg=";

import axios from "axios";

export default {
  name: 'Step2',
  mounted() {
    this.loadProgress();
  },
  data() {
    return {
      user: {},
      school: {},
      schoolItem: [],
      headcount: 20,
      quote: {
        level1: {
          level: 1,
          headcount: 0,
          enabled: false,
          premium: 200,
          ref: '',
          accept: false,
          email: false,
          loading: false,
          quote: {}
        },
        level2: {
          level: 2,
          headcount: 0,
          enabled: false,
          premium: 980,
          ref: '',
          accept: false,
          email: false,
          loading: false,
          quote: {}
        },
        level3: {
          level: 3,
          headcount: 0,
          enabled: false,
          premium: 0,
          ref: '',
          accept: false,
          email: false,
          loading: false,
          quote: {}
        }
      },
      loadingQuotes: false,
      showQuotes: false,
    }
  },
  methods: {
    nextStep() {
      this.user.step = 'complete';
      this.$emit('stepFinished', [this.user, this.school, this.schoolItem]);
    },
    loadProgress() {
      let retrievedQuote = localStorage.getItem("ls_quote");
      let retrievedUser = localStorage.getItem("ls_user");
      let retrievedSchool = localStorage.getItem("ls_school");

      if(!retrievedQuote || !retrievedUser || !retrievedSchool) {
        this.$router.push('/');
      }
      //decrypt
      if(retrievedQuote) {
        let bytesQuote = this.$CryptoJS.AES.decrypt(retrievedQuote, _token);
        let decryptedQuote = JSON.parse(
          bytesQuote.toString(this.$CryptoJS.enc.Utf8)
        );
        this.schoolItem = decryptedQuote;
      }
      if(retrievedUser) {
        let bytesUser = this.$CryptoJS.AES.decrypt(retrievedUser, _token);
        let decryptedUser = JSON.parse(
          bytesUser.toString(this.$CryptoJS.enc.Utf8)
        );
        this.user = decryptedUser;
        this.user.step = 3;
      }

      if(retrievedSchool) {
        let bytesSchools = this.$CryptoJS.AES.decrypt(retrievedSchool, _token);
        let decryptedSchools = JSON.parse(
          bytesSchools.toString(this.$CryptoJS.enc.Utf8)
        );
        this.school = decryptedSchools;
      }

    },
    updateQuotes() {
      //add comment to KIS to update quotes
      let headcount = this.headcount;
      if(headcount != this.quote.level1.headcount && headcount != this.quote.level2.headcount) {
        this.quote.level1.headcount = headcount;
        this.quote.level2.headcount = headcount;
      }
      else {
        this.$swal('Quotes Not Updated', 'You have not changed the headcount, your quotes cannot be updated. Please try again', 'warning')
        return;
      }
      
      if(this.checkHeadcount(headcount)) {
        return;
      }
      else {
        this.showQuotes = true;
        this.loadingQuotes = true;

        if(!this.generateLevel1(headcount)) {
          this.$swal('Error Updating Quotes', 'There appears to be an error updating your quotes. Please try again', 'error');
          return;
        }
        if(!this.generateLevel2(headcount)) {
          this.$swal('Error Updating Quotes', 'There appears to be an error updating your quotes. Please try again', 'error');
          return;
        }

        setTimeout(() => this.loadingQuotes = false, 500);
      }

      this.$gtag.event('updateQuotes', {
        'event_category': 'general',
        'event_label': 'updateQuotesClicked',
      })

    },
    checkHeadcount(headcount) {
      if(headcount < 20) {
        this.$swal('Headcount Too Low', 'The headcount must be 20 or more. Please try again', 'warning');
        this.headcount = 20;
        return true;
      }
      return false;
    },
    generateLevel1(headcount) {
      this.quote.level1 = {
          level: 1,
          headcount: this.headcount,
          enabled: false,
          premium: 200,
          ref: '',
          accept: false,
          email: false,
          loading: false,
        }
      this.quote.level1.premium = 10 * headcount;
      return true;
    },
    generateLevel2(headcount) {
      this.quote.level2 = {
          level: 2,
          headcount: this.headcount,
          enabled: false,
          premium: 980,
          ref: '',
          accept: false,
          email: false,
          loading: false,
        }

      let costPerHead = 39;
      if(headcount < 100) {
          costPerHead = 39;
      }
      else if(headcount >= 100 && headcount <= 200) {
          costPerHead = 29;
      }
      else if(headcount > 200) {
          costPerHead = 19;
      }
      this.quote.level2.premium = costPerHead * headcount;
      return true;
    },
    createQuote(level) {
      let self = this;
      let quote = {};
      if(level == 1) {
        quote = this.quote.level1;
        this.quote.level1.loading = true;
      }
      if(level == 2) {
        quote = this.quote.level2;
        this.quote.level2.loading = true;
      }
      axios.post(api + 'create-wbo-quote', {token: _token, school: this.school, user: this.user, schoolItem: this.schoolItem, quote: quote, headcount: this.headcount})
      .then(response => {
        if(response.data.error == false) {
          let quoteData = response.data.data;
          if(level == 1) {
            self.quote.level1.enabled = true;
            self.quote.level1.ref = quoteData.quote_reference;
            self.quote.level1.loading = false;
            self.quote.level1.quote = quoteData;

            self.$gtag.event('createQuoteClicked', {
                'event_category': 'general',
                'event_label': 'createQuoteOne',
              })
          }
          if(level == 2) {
            self.quote.level2.enabled = true;
            self.quote.level2.ref = quoteData.quote_reference;
            self.quote.level2.loading = false;
            self.quote.level2.quote = quoteData;

            self.$gtag.event('createQuoteClicked', {
                'event_category': 'general',
                'event_label': 'createQuoteTwo',
              })
          }

          const data = {
            token: _token,
            enquiry_callback: self.user.callback,
            enquiry_stage: 3,
            enquiry_reference: quoteData.quote_reference,
            quote_id: quoteData.quote_id
          }
          
          axios.put(api + "enquiry/" + self.user.enquiryID, data)

          localStorage.removeItem("ls_quote");
          localStorage.removeItem("ls_user");
          localStorage.removeItem("ls_school");
          return;
        }
        else {
          this.$swal('Error Creating Quote', 'There appears to be an error creating your quote. Please try again', 'error')
          return;
        }
      })
    },
    acceptQuote(level) {
      let self = this;

      let ref = '';
      let id = '';

      if(level == 1) {
        ref = this.quote.level1.quote.quote_reference,
        id = this.quote.level1.quote.quote_id
      }
      if(level == 2) {
        ref = this.quote.level2.quote.quote_reference,
        id = this.quote.level2.quote.quote_id
      }

      const data = {
        token: _token,
        enquiry_callback: self.user.callback,
        enquiry_stage: 'complete',
        enquiry_reference: ref,
        quote_id: id
      }
      
      axios.put(api + "enquiry/" + self.user.enquiryID, data)
      return;
    },
    emailQuote(level) {

      let self = this;

      let q;
      if(level == 1) {
        this.quote.level1.email = 'sending';
        q = this.quote.level1.quote;
      }
      if(level == 2) {
        this.quote.level2.email = 'sending';
        q = this.quote.level2.quote;
      }
      let id = q.quote_id


      if (self.user.enquiryID) {
        // now send the email
        let params2 = {
          token      : _token,
          quoteID    : id,
          recipients : [{
              association : self.user.contact_organisation,
              email       : self.user.contact_email,
              id          : self.user.userID,
              name        : self.user.contact_name
          }]
        };
        axios.post(api + 'quote/send', params2)
        .then(() => {
          // show that the email has been sent
          if (level == 1) {
              self.quote.level1.quote.sent = true;
              self.quote.level1.email = 'sent';

              self.$gtag.event('emailQuoteClicked', {
                'event_category': 'general',
                'event_label': 'emailQuoteOne',
              })
          }
          if (level == 2) {
              self.quote.level2.quote.sent = true;
              self.quote.level2.email = 'sent';

              self.$gtag.event('emailQuoteClicked', {
                'event_category': 'general',
                'event_label': 'emailQuoteTwo',
              })
          }     
          
          });
      }
    },
  }
}
</script>